import React from "react";
import { Link } from "gatsby";
import "reactjs-popup/dist/index.css";

const Button = ({ className, link, children, ...props }) => {
  return (
    <Link to={link} className={"button " + className}>
      {children}
    </Link>
  );
};

export default Button;

export const HalfButton = props => {
  return (
    <div className="multibtnHolder">
      <div className="button blackbtn">€18.5/month</div>
      <div className="button halfbtn">Start Now</div>
    </div>
  );
};

const fbPixel = () => {
  window.fbq("track", "AddToCart", {
    content_type: "product",
    content_ids: ["vitamin"],
  });
};

export const HalfButton2 = ({ className, price = "€ 0.60/μέρα", ...props }) => {
  let benefitPosition = "";
  if (className && className.indexOf("benefitbtn") > -1) {
    benefitPosition = " benefitposition";
  }
  return (
    <a
      // href="https://account.greatforwomen.com/checkout"
      href={process.env.GATSBY_API_URL}
      className="checkoutbtn"
      title="Αγορά Βιταμίνης"
      onClick={e => fbPixel()}
    >
      <div className={"ctaHolder smallMargin" + benefitPosition}>
        <div className="button ctabtn" style={{ width: "250px", padding: "12px 0" }}>
          {/* {price} <div className="btnDivider"></div> */}
          {props.lang === "gr" ? " Ξεκίνα τώρα" : "Shop Now"}
        </div>
      </div>
    </a>
  );
};



export const Guarantees = ({ data, lang }) => {
  const freeShipping = data && data.find(
    el => el.node.label === "home-banner-footer-title-1"
  );
  const freeShipping1 = data && data.find(
    el => el.node.label === "home-banner-footer-title-2"
  );
  const freeShipping2 = data && data.find(
    el => el.node.label === "home-banner-footer-description"
  );

  return (<div className="guarantees">
    {/* <span className="underline">{freeShipping && freeShipping.node[lang]}</span>
    <sup>1</sup>, <br className="brhidden" />
    <span className="underline">{freeShipping1 && freeShipping1.node[lang]}</span> */}
    <span className="normal">
      {freeShipping && freeShipping.node[lang]}
    </span>
    {/* <sup>1</sup> */}
    <br />
    {/* <sup>1</sup> */}
    <span className="normal">
      {freeShipping2 && freeShipping2.node[lang]}
    </span>
  </div>
  )
};
