import React, { useState } from "react";
import addToMailchimp from "gatsby-plugin-mailchimp";

const Follow = ({ hideSocial, lang, data }) => {
  const [email, setEmail] = useState("");
  const [disabled, updateDisabled] = useState(false);
  const [submitted, updateSubmitted] = useState(false);
  const submitNewsletter = e => {
    e.preventDefault();
    updateDisabled(true);
    addToMailchimp(email)
      .then(d => {
        updateSubmitted(true);
        window.gtag("event", "conversion", {
          send_to: "AW-455994588/63ZPCOv5pPECENzZt9kB",
        });
      })
      .catch(e => {
        console.log("mailchimp error");
      });
  };

  const news = data && data.find((el) => el.node.label === "join-our-newsletter")
  const thanks = data && data.find((el) => el.node.label === "thanks-check-your-mail")
  const yourEmail = data && data.find((el) => el.node.label === "newsletter_email")
  const benefitButton1 = data && data.find(
    el => el.node.label === "homepage-footer-title"
  );
  return (
    <div className="follow">
      {!hideSocial && (
        <>
          <h3>{benefitButton1 && benefitButton1.node[lang]}</h3>
          <div className="followIcons">
            <a
              href="https://www.facebook.com/greatforwomen"
              target="_blank"
              rel="noopener"
              className="followLink"
              title="great on facebook"
            >
              <img alt="facebook icon" src="/facebook-logo-white.svg" />
            </a>
            <span className="spacer"></span>
            {/* <br className="brhidden" /> */}
            {/* <div className="mobilespace">&nbsp;</div> */}
            <a
              href="https://instagram.com/greatforwomen"
              target="_blank"
              rel="noopener"
              className="followLink"
              title="great on instagram"
            >
              <img alt="instagram icon" src="/instagram-logo-white.svg" />
            </a>
            <span className="spacer"></span>
            <a
              href="https://www.youtube.com/channel/UC5xQKPlISp5lkXn_2VQZtlw"
              target="_blank"
              rel="noopener"
              className="followLink"
              title="great on youtube"
            >
              <img alt="youtube icon" src="/youtube.svg" width="24" />
            </a>
            <span className="spacer"></span>
            <a
              href="https://gr.pinterest.com/greatforwomen"
              target="_blank"
              rel="noopener"
              className="followLink"
              title="great on pinterest"
            >
              <img alt="pinterest icon" src="/pinterest.svg" width="24" />
            </a>
          </div>
        </>
      )}
      <div className="multibtnHolder">
        <form onSubmit={submitNewsletter}>
          <input
            type="text"
            placeholder={yourEmail && yourEmail.node[lang]}
            name="email"
            value={email}
            onChange={e => setEmail(e.target.value)}
          ></input>
          <div
            className={
              disabled || email === ""
                ? "button halfbtn disabled"
                : "button halfbtn"
            }
            onClick={submitNewsletter}
          >
            {submitted ? "Thanks! Check your mail" : "Join our newsletter"}
          </div>
        </form>
      </div>
    </div>
  );
};

export default Follow;
